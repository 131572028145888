import { Dispatch, ReactNode, SetStateAction, memo } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { styled } from 'styled-system/jsx'
import { contentStyle, overlayStyle, titleStyle, descriptionStyle, animateTransition } from './styles';
import { animated, useTransition } from '@react-spring/web';
import { Cross2Icon } from '@radix-ui/react-icons';
import { IconButton } from 'src/components/common/IconButton'
import { useInternalExternalState } from '@/hooks/useInternalExternalState';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

const DialogRoot = styled(DialogPrimitive.Root, {
    base: {}
});
const DialogTrigger = DialogPrimitive.Trigger;
const DialogOverlay = styled(animated(DialogPrimitive.Overlay), overlayStyle);
const DialogContent = styled(animated(DialogPrimitive.Content), contentStyle);
const DialogTitle = styled(DialogPrimitive.Title, titleStyle);
const DialogDescription = styled(DialogPrimitive.Description, descriptionStyle);
const DialogClose = DialogPrimitive.Close;
const DialogPortal = DialogPrimitive.Portal;


export const AlertDialogOverlay = styled(animated(AlertDialogPrimitive.Overlay), overlayStyle);
export const AlertDialogRoot = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogContent = styled(animated(AlertDialogPrimitive.Content), contentStyle);
export const AlertDialogTitle = styled(AlertDialogPrimitive.Title, titleStyle);
export const AlertDialogDescription = styled(AlertDialogPrimitive.Description, descriptionStyle);
export const AlertDialogAction = AlertDialogPrimitive.Action;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;
export const AlertDialogPortal = AlertDialogPrimitive.Portal

const Dialog = ({ trigger, title, description, children, hasPadding = true, open, setOpen, overlayBrightness: dialogBrightness, type = 'dialog' }:
    {
        trigger?: JSX.Element, title?: string, description?: string,
        children?: ReactNode, hasPadding?: boolean,
        open?: boolean,
        setOpen?: Dispatch<SetStateAction<boolean>>,
        overlayBrightness?: 'dark' | 'light' | 'lighter' | 'medium-light',
        type?: 'dialog' | 'alert',
    }
) => {
    const { internalState: openInternal, setInternalState: setInternalOpen } = useInternalExternalState<boolean>(false, open, setOpen)
    const transition = useTransition(openInternal, animateTransition)

    const Overlay = (type === 'dialog' ? DialogOverlay : AlertDialogOverlay) as any // ts-check too long
    const Content = type === 'dialog' ? DialogContent : AlertDialogContent
    const Title = type === 'dialog' ? DialogTitle : AlertDialogTitle
    const Description = type === 'dialog' ? DialogDescription : AlertDialogDescription
    const Trigger = type === 'dialog' ? DialogTrigger : AlertDialogTrigger
    const Root = type === 'dialog' ? DialogRoot : AlertDialogRoot
    const Close = type === 'dialog' ? DialogClose : AlertDialogCancel
    const Portal = type === 'dialog' ? DialogPortal : AlertDialogPortal

    return (
        <Root open={openInternal} onOpenChange={(x) => setInternalOpen(x)} >
            {trigger && <Trigger asChild>
                {trigger}
            </Trigger>}
            <Portal forceMount>
                {transition((style, isOpen) => {
                    return (
                        <>
                            {isOpen ? (
                                <Overlay brightness={dialogBrightness} style={{ opacity: style.opacity }} ><Content hasPadding={hasPadding} style={{ ...style, }} forceMount>
                                    {title && <Title>{title}</Title>}
                                    {description && <Description>{description}</Description>}
                                    {children}
                                    <Close asChild>
                                        <IconButton aria-label="Close">
                                            <Cross2Icon />
                                        </IconButton>
                                    </Close>
                                </Content></Overlay>
                            ) : null}
                        </>
                    )
                })}
            </Portal>
        </Root>
    )
}

export default memo(Dialog)
