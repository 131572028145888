import { cva } from 'styled-system/css'
import { styled } from 'styled-system/jsx'

export const ErrorText = styled('sub', {
    base: {
        color: '$error11',
    },
})

const formStyles = cva({
    base: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 'formPadding',
        paddingTop: 5,
        gap: 'formGap',

        background: '$primary2',

        transition: 'background-color 500ms linear, color 750ms linear',
    },
})

export const Form = styled('form', formStyles)
export const DivForm = styled('div', formStyles)

export const ButtonsBar = styled('div', {
    base: {
        center: 'row',
        gap: 15,
        gridArea: 'submit',
        flexDirection: 'row-reverse',
    },
})

export const Field = styled('fieldset', {
    base: {
        all: 'unset',
        display: 'flex',
        gap: 15,
        gridArea: 'due',
    },
})

export const ColorDiv = styled('div', {
    variants: {
        num: {
            0: {
                background: '$primary3',
            },
            1: {
                background: '$primary4',
            },
            2: {
                background: '$primary5',
            },
            3: {
                background: '$primary6',
            },
        },
        size: {
            large: {
                size: '500px',
            },
        },
    },
    base: {
        size: '50px',
        background: '$primary3',
    },
})
